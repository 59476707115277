import { Button, Card, Col, DatePicker, Form, Input, Row, Select, TimePicker, Typography } from 'antd';
import DmnModeler from 'dmn-js/lib/Modeler';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';
import TenantInfo from '../../../../components/TenantIdInfo';
import _ from '../../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../../helpers/toast.helpers';
import PrimaryLayout from '../../../../layouts/primary-layout';
import { useLoader } from '../../../../stores/use-loader';
import { createRuleTriggerTypeData, triggerTypeSelectOptions } from '../../data';
import { loyaltyService } from '../../services/loyalty.service';

import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useTimezone } from '../../../../hooks/useTimezone';
import '../../../../i18n';
import { IRuleDescription } from '../../types/earn-rules';

interface ICreateEarnRulesProps {}

const CreateEarnRules: React.FunctionComponent<ICreateEarnRulesProps> = props => {
  const [createForm] = Form.useForm();
  const [ruleDescription, setRuleDescription] = React.useState({} as IRuleDescription);
  const [selectedRuleInputField, setSelectedRuleInputField] = React.useState('');
  const [selectedRuleRewardField, setSelectedRewardField] = React.useState('');
  const { t } = useTranslation();

  let modellerRef = React.useRef<any>(null);

  const handleApplicabilityChange = async () => {
    setLoading(true);
    detach();
    const { trigger_type, applicablity_type } = createForm.getFieldsValue();

    const { errors, data } = await loyaltyService.getRuleDescriptions({
      applicabilityType: applicablity_type,
      triggerType: trigger_type
    });
    if (_.isEmpty(errors)) {
      setRuleDescription(data);
      if (data.input?.length) setSelectedRuleInputField(data.input[0].field);
      if (data.result?.length) setSelectedRewardField(data.result[0].field);
      loadtableData();
    } else {
      displayErrorNotifications(errors);
    }

    setLoading(false);
  };

  const loadtableData = async () => {
    const { trigger_type, applicablity_type } = createForm.getFieldsValue();
    const bodyData = {
      rule_type: 'EARN',
      trigger_type: trigger_type, //EVENT,SCHEDULED
      applicability_type: applicablity_type // Order,Sign up
    };
    const { data, errors } = await loyaltyService.getRuleTable(bodyData);
    if (_.isEmpty(errors)) {
      initializeModeller(data?.xml);
    } else {
      displayErrorNotifications(errors);
    }
  };

  const navigate = useNavigate();

  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));

  React.useEffect(() => {
    // if (modellerRef.current === null) {
    //   initializeModeller();
    // }
    return () => {
      if (modellerRef.current !== null) {
        modellerRef.current.detach();
        modellerRef.current = null;
      }
    };
  }, []);

  const detach = () => {
    if (modellerRef.current !== null) {
      modellerRef.current.detach();
      modellerRef.current = null;
    }
  };

  const initializeModeller = async (loadedXmlData: any) => {
    const modeller = new DmnModeler({
      container: '#createEarnRulesCanvas'
    });
    modellerRef.current = modeller;

    try {
      const { warnings } = await modeller.importXML(loadedXmlData); //createEarnRulesInitialXml

      if (warnings.length) {
        console.log('import with warnings', warnings);
      } else {
        console.log('import successful');
      }
    } catch (err) {
      console.log('something went wrong:', err);
    }
  };

  const handleCreate = async () => {
    setLoading(true);
    const { name, applicablity_type, trigger_type, schedule_time, schedule_date, date_range, timezone } =
      createForm.getFieldsValue();

    const { xml } = await modellerRef.current.saveXML();
    if (!xml.includes('<inputEntry')) {
      displayErrorNotifications([{ message: t('pleaseAddAtleastOneRuleCondition') }]);
    } else {
      const request: any = {
        name,
        applicability_type: applicablity_type,
        trigger_type,
        xml,
        timezone
      };

      if (schedule_time) {
        const datePart = schedule_date.format('YYYY-MM-DD');
        const timePart = schedule_time.format('HH:mm:ss');

        // Combine date and time to create a new Day.js object
        const combinedDateTime = dayjs(`${datePart}T${timePart}`);

        request.scheduled_time = combinedDateTime.format();
      }

      if (date_range?.length) {
        const [start_date, end_date] = date_range;
        request.start_date = start_date.format();
        request.end_date = end_date.format();
      }

      const { errors } = await loyaltyService.createEarnRule(request);
      if (_.isEmpty(errors)) {
        displaySuccessNotification({ message: t('ruleCreatedSuccessfully') });
        navigate('/loyalty/config/earn-rule');
      } else {
        displayErrorNotifications(errors);
      }
    }
    setLoading(false);
  };

  const ruleInputFieldsSelectOption = (ruleDescription?.input || []).map(({ expressions, field }) => {
    return {
      label: field,
      value: field
    };
  });

  const ruleRewardFieldSelectOptions = (ruleDescription?.result || []).map(({ expressions, field }) => {
    return {
      label: field,
      value: field
    };
  });

  const selectedInputFieldObject = (ruleDescription?.input || []).find(item => item.field === selectedRuleInputField);
  const selectedRewardFieldObject = (ruleDescription?.result || []).find(
    item => item.field === selectedRuleRewardField
  );

  const inputFieldTableData = (selectedInputFieldObject?.expressions || []).map(
    ({ description, expression, values }) => {
      return {
        description,
        expression,
        values
      };
    }
  );

  const rewardFieldTableData = (selectedRewardFieldObject?.expressions || []).map(
    ({ description, expression, values, output, type }) => {
      return {
        description,
        expression,
        values,
        output,
        type
      };
    }
  );

  const { timezoneSelectOptions } = useTimezone();

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                {t('createRule')}
              </Typography.Title>
            </Col>
          </Row>
          <div className="flex gap-4">
            <TenantInfo />
          </div>

          <section className="mt-4">
            <Form layout="vertical" onFinish={handleCreate} form={createForm}>
              <Row gutter={12}>
                <Col xs={24} md={6}>
                  <Form.Item
                    name="trigger_type"
                    label={t('triggerType')}
                    rules={[{ message: t('thisFieldIsRequired'), required: true }]}
                  >
                    <Select
                      placeholder={t('selectTriggerType')}
                      size="large"
                      onChange={() => createForm.setFieldValue('applicablity_type', null)}
                      options={triggerTypeSelectOptions}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) => {
                      return prevValues.trigger_type !== curValues.trigger_type;
                    }}
                  >
                    {form => {
                      const { trigger_type } = form.getFieldsValue();
                      const options = createRuleTriggerTypeData[trigger_type]?.applicabilityTypes || [];

                      return (
                        <Form.Item
                          rules={[{ message: t('thisFieldIsRequired'), required: true }]}
                          name="applicablity_type"
                          label={t('applicabilityType')}
                        >
                          <Select
                            placeholder={t('applicabilityType')}
                            size="large"
                            onChange={handleApplicabilityChange}
                            options={options}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>

                <Col xs={24} md={6}>
                  <Form.Item
                    rules={[
                      { message: t('thisFieldIsRequired'), required: true },
                      {
                        validator: async (_, ruleName: string) => {
                          if (/\s/.test(ruleName)) {
                            return Promise.reject(new Error(t('noSpacesAllowedInRuleName')));
                          }
                        }
                      }
                    ]}
                    name="name"
                    label={t('ruleName')}
                  >
                    <Input placeholder={t('enterRuleName')} size="large" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) => {
                    return prevValues.trigger_type !== curValues.trigger_type;
                  }}
                >
                  {form => {
                    const { trigger_type } = form.getFieldsValue();
                    return trigger_type === createRuleTriggerTypeData?.SCHEDULED?.value ? (
                      <>
                        <Col xs={24} md={6}>
                          <Form.Item
                            rules={[{ message: t('thisFieldIsRequired'), required: true }]}
                            name="schedule_date"
                            label={t('scheduleDate')}
                          >
                            <DatePicker className="w-full" size="large" placeholder={t('scheduleDate')} />
                          </Form.Item>
                        </Col>{' '}
                        <Col xs={24} md={6}>
                          <Form.Item
                            rules={[{ message: t('thisFieldIsRequired'), required: true }]}
                            name="schedule_time"
                            label={t('scheduleTime')}
                          >
                            <TimePicker
                              className="w-full"
                              size="large"
                              format={'hh:mm A'}
                              placeholder={t('scheduleTime')}
                            />
                          </Form.Item>
                        </Col>
                      </>
                    ) : null;
                  }}
                </Form.Item>
                <Col xs={24} md={6}>
                  <Form.Item
                    rules={[{ message: t('thisFieldIsRequired'), required: true }]}
                    name="timezone"
                    label={t('timezone')}
                  >
                    <Select options={timezoneSelectOptions} placeholder={t('selectTimezone')} size="large" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item name="date_range" label={t('date')}>
                    <DatePicker.RangePicker size="large" placeholder={[t('startDate'), t('endDate')]} />
                  </Form.Item>
                </Col>
              </Row>
              {/* {!_.isEmpty(ruleDescription) && (
                <Row gutter={12} className="my-4">
                  <Col xs={24} md={10}>
                    <Typography.Title level={4} className="text-[#2e2a5b]">
                      {t('ruleDescriptionInputs')}
                    </Typography.Title>

                    <section className="my-2">
                      <Row>
                        <Col xs={24} md={12}>
                          <Select
                            placeholder={t('selectField')}
                            value={selectedRuleInputField}
                            options={ruleInputFieldsSelectOption}
                            onChange={value => setSelectedRuleInputField(value)}
                            className="w-full"
                            size="large"
                          ></Select>
                        </Col>
                      </Row>
                    </section>
                    <Table
                      loading={false}
                      bordered
                      pagination={false}
                      dataSource={inputFieldTableData}
                      columns={[
                        {
                          title: t('description'),
                          render(value, record, index) {
                            return <>{record.description}</>;
                          }
                        },
                        {
                          title: t('expression'),
                          render(value, record, index) {
                            return <Typography.Text copyable>{record.expression}</Typography.Text>;
                          }
                        },
                        {
                          title: t('exampleValueS'),
                          render(value, record, index) {
                            return <>{record.values}</>;
                          }
                        }
                      ]}
                    ></Table>
                  </Col>{' '}
                  <Col xs={24} md={14}>
                    <Typography.Title level={4} className="text-[#2e2a5b]">
                      {t('ruleDescriptionReward')}
                    </Typography.Title>
                    <section className="my-2">
                      <Row gutter={[12, 12]}>
                        <Col xs={24} md={8}>
                          <Select
                            placeholder={t('selectField')}
                            value={selectedRuleRewardField}
                            options={ruleRewardFieldSelectOptions}
                            onChange={value => setSelectedRewardField(value)}
                            className="w-full"
                            size="large"
                          ></Select>
                        </Col>
                        {selectedRewardFieldObject?.mandatory && (
                          <Col xs={24} md={8}>
                            <Alert message={'thisFieldIsMandatory'} type="info" showIcon />
                          </Col>
                        )}
                      </Row>
                    </section>
                    <Table
                      loading={false}
                      pagination={false}
                      bordered
                      scroll={{ x: 500 }}
                      dataSource={rewardFieldTableData}
                      columns={[
                        {
                          title: t('output'),
                          render(value, record, index) {
                            return <Typography.Text copyable>{record.output}</Typography.Text>;
                          }
                        },
                        {
                          title: t('type'),
                          render(value, record, index) {
                            return <>{record.type}</>;
                          }
                        },

                        {
                          title: t('exampleValueS'),
                          render(value, record, index) {
                            return <>{record.values}</>;
                          }
                        }
                      ]}
                    ></Table>
                  </Col>
                </Row>
              )} */}
              <div id="createEarnRulesCanvas" className="h-[400px]"></div>
              <Row gutter={12}>
                <Col xs={24} md={8} lg={6}>
                  <Button block htmlType="submit" size="large" type="primary">
                    <BoldButtonLabel labelText={t('create')} />{' '}
                  </Button>
                </Col>
                <Col xs={24} md={8} lg={6}>
                  <Button block size="large" onClick={() => navigate(`/loyalty/config/earn-rule`)}>
                    <BoldButtonLabel labelText={t('back')} />{' '}
                  </Button>
                </Col>
              </Row>{' '}
            </Form>
          </section>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default CreateEarnRules;
