// src/i18n.ts
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslations from './locales/en/translation.json';
import esTranslations from './locales/sp/translation.json';
// import { useLocale } from './stores/use-locale';
// const { locale, setUserLocale } = useLocale(({ locale, setUserLocale }) => ({ locale, setUserLocale }));

i18n.use(initReactI18next).init({
  resources: {
    IN: {
      translation: enTranslations
    },
    CL: {
      translation: esTranslations
    }
  },
  lng: localStorage.getItem('createOrderTokens') ? JSON.parse(localStorage.getItem('createOrderTokens') || '{}')?.state?.locale || 'IN' : 'IN',
  fallbackLng: 'IN',
  interpolation: {
    escapeValue: false // React already safes from xss
  }

});
export default i18n;
