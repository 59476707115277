import { Card, Col, Row, Tabs, Typography } from 'antd';
import TabPane from 'antd/es/tabs/TabPane';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';
import TenantInfo from '../../../../components/TenantIdInfo';
import PrimaryLayout from '../../../../layouts/primary-layout';
import PromoBulkUpload from './promo-bulk-upload';
import PromoUploadReport from './promo-upload-report';

interface IBulkUploadPageConfiguration {}

const BulkUploadPageConfiguration: React.FunctionComponent<IBulkUploadPageConfiguration> = () => {
  const { t } = useTranslation();
  const [tabKey, setTabKey] = React.useState<string>('1');
  const handleTabChange = (key: string) => {
    setTabKey(key);
  };
  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                {t('bulkUpload')}
              </Typography.Title>
            </Col>
          </Row>
          <TenantInfo />
          <div className="mt-4">
            <Tabs defaultActiveKey="1" onChange={handleTabChange}>
              <TabPane tab={<BoldButtonLabel labelText={t('fileUpload')} />} key="1">
                <PromoBulkUpload active={tabKey === '1'} />
              </TabPane>
              <TabPane tab={<BoldButtonLabel labelText={t('UploadReport')} />} key="2">
                <PromoUploadReport active={tabKey === '2'} />
              </TabPane>
            </Tabs>
          </div>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default BulkUploadPageConfiguration;
