import { Card, Descriptions, Modal, Table, Typography } from "antd";
import React from "react"
import ActionButton from "../../components/ActionButton";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

interface IAuditModalProps {
    auditModal: boolean
    handleOnCloseAuditModal: () => void;
    auditDetails: any
}

const AuditModal: React.FunctionComponent<IAuditModalProps> = ({ auditModal, handleOnCloseAuditModal, auditDetails }) => {
    const [skuAudit, setSkuAudit] = React.useState(false)
    const [auditTags, setAuditTags] = React.useState([] as any)
    const {t} = useTranslation();

    React.useEffect(() => {
    }, [handleOnCloseAuditModal])

    const handleOnSkuAduitClose = () => {
        setSkuAudit(false)
    }

    const auditDetailsColumn: any = [
        {
            title: t('skuId'),
            align: 'center',
            render: (_: any, record: any) => {
                return <>{record?.sku_id}</>;
            }
        },
        {
            title: t('statusDescription'),
            align: 'center',
            render: (_: any, record: any) => {
                return <>{t(record?.status_description)}</>;
            }
        },
        {
            title: t('audit'),
            align: 'center',
            render: (_: any, record: any) => {
                return <ActionButton
                    action="VIEW"
                    onClick={() => {
                        setAuditTags(record?.tags)
                        setSkuAudit(true)
                    }}
                    title={t(`viewAudit`, { skuId: record?.sku_id })}
                />
            }
        },
    ]

    const statusColumn: any = [
        {
            title: t('statusDescription'),
            align: 'center',
            render: (_: any, record: any) => {
                return <>{t(record?.status_description)}</>;
            }
        },
        {
            title: t('createdAt'),
            align: 'center',
            render: (_: any, record: any) => {
                let date = record?.created_at ? `${dayjs(record?.created_at).format('YYYY-MM-DD')} ${dayjs(record?.created_at).format('HH:mm:ss')}` : ''
                return <>{date}</>;
            }
        },
        {
            title: t('quantity'),
            align: 'center',
            render: (_: any, record: any) => {
                let quantity = record?.quantity?.number_of_units * record?.quantity?.unit_fraction
                return <>{quantity}</>;
            }
        }
    ]

    return (<>
        <section className="mt-8">
            <Table
                pagination={false}
                bordered
                columns={auditDetailsColumn}
                dataSource={auditDetails.item_list}
            />
            <Modal
                title={''}
                footer={null}
                maskClosable={false}
                centered
                width={1000}
                open={skuAudit}
                onCancel={() => {
                    handleOnSkuAduitClose()
                }}
            >
                <div className="max-h-[500px] overflow">
                    {auditTags.map((item: any) => {
                        return (
                            <>
                                <Typography.Title level={5} className="text-[#2e2a5b]">
                                    {t('audit')}
                                </Typography.Title>
                                <Card className="mb-2">
                                    <Typography.Title level={5} className="text-[#2e2a5b]">
                                        {t('tagId')}: {item?.tag_id}
                                    </Typography.Title>
                                    <Descriptions size="middle" bordered className="mb-2">
                                        <Descriptions.Item span={1} label={t('tagType')}>
                                            <span className=""> {t(item?.tag_type)}</span>
                                        </Descriptions.Item>
                                        <Descriptions.Item span={1} label={t('orderedQuantity')}>
                                            <span className=""> {item?.original_quantity?.number_of_units * item?.original_quantity?.unit_fraction}</span>
                                        </Descriptions.Item>
                                        <Descriptions.Item span={1} label={t('fulfillableQuantity')}>
                                            <span className=""> {item?.quantity?.number_of_units * item?.quantity?.unit_fraction}</span>
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Table
                                        pagination={false}
                                        bordered
                                        columns={statusColumn}
                                        dataSource={item?.statuses}
                                        scroll={{ y: 400 }}
                                    />
                                </Card>
                            </>
                        )
                    })}
                </div>
            </Modal>
        </section>
    </>)
}

export default AuditModal
