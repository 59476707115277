import { AxiosError } from 'axios';
import { errorConstants } from '../constants/error-codes';
import axiosClient from './axios.client';

interface HttpCallOptions {
  url: string;
  method: string;
  body?: any;
  headers?: any;
  params?: any;
  responseType?: any;
  timeout?: any;
}
// function sleep(ms = 1000) {
//   return new Promise(resolve => setTimeout(resolve, ms));
// }

interface HttpClientOptions {
  url: string;
  body?: any;
  headers?: any;
  params?: any;
  responseType?: any;
  timeout?: any;
}

const httpCall = async ({
  url,
  method,
  body = undefined,
  headers = {},
  params = {},
  responseType = undefined,
  timeout = undefined
}: HttpCallOptions) => {
  try {
    const {
      data,
      status,
      headers: responseHeaders
    } = await axiosClient.request({
      url,
      method,
      data: body,
      params,
      headers,
      responseType,
      timeout
    });
    return { data, status, headers: responseHeaders, errors: [] };
  } catch (error) {
    const typedError = error as AxiosError;
    const errorList = parseError(error);
    return {
      data: null,
      status: typedError?.response?.status,
      headers: null,
      errors: errorList
    };
  }
};

const parseError = (error: any) => {
  return error?.response?.data?.errors || [{ message: errorConstants.GENERIC_ERROR }];
};
const httpClient = {
  get: (args: HttpClientOptions) => httpCall({ ...args, method: 'GET' }),
  post: (args: HttpClientOptions) => httpCall({ ...args, method: 'POST' }),
  put: (args: HttpClientOptions) => httpCall({ ...args, method: 'PUT' }),
  delete: (args: HttpClientOptions) => httpCall({ ...args, method: 'DELETE' }),
  patch: (args: HttpClientOptions) => httpCall({ ...args, method: 'PATCH' })
};

export default httpClient;
