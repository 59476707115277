import { Button, Card, Form, Input, Select } from 'antd';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import BoldButtonLabel from '../../components/BoldButtonLabel';
import { ENV } from '../../config/envConfig';
import { localeEjjeList, localeList } from '../../data/intl-data';
import _ from '../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../helpers/toast.helpers';
import { loggingIn } from '../../services/login.services';
import { useAuth } from '../../stores/use-auth';
import { useLocale } from '../../stores/use-locale';
import './login.css';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { urlHelpers } from '../../helpers';
import i18n from '../../i18n';
import { images } from '../../utils/images';
import './login.css';

interface ILoginProps {}

const TransparentSelect = styled(Select)`
  .ant-select-selector {
    background-color: transparent !important;
    border: none !important;
    color: white;
  }

  .ant-select-arrow {
    color: white;
  }

  .ant-select-item-option-content {
    color: white;
  }

  .ant-select-item-option {
    background-color: transparent !important;
    color: white;
  }

  .ant-select-item-option-selected {
    color: white !important;
    background-color: rgba(255, 255, 255, 0.2) !important; 
`;

const Login: React.FunctionComponent<ILoginProps> = () => {
  const [loginForm] = Form.useForm();
  const { t } = useTranslation();
  const { isLoggedIn, setIsLoggedIn, accessTokenData, setAccessToken, setBussinessGroupName, setTenent, tenantSecret } =
    useAuth(
      ({
        isLoggedIn,
        setIsLoggedIn,
        accessTokenData,
        setAccessToken,
        setBussinessGroupName,
        setTenent,
        tenantSecret
      }) => ({
        isLoggedIn,
        setIsLoggedIn,
        accessTokenData,
        setAccessToken,
        setBussinessGroupName,
        setTenent,
        tenantSecret
      })
    );
  const { locale, setUserLocale } = useLocale(({ locale, setUserLocale }) => ({ locale, setUserLocale }));

  const navigate = useNavigate();

  const { businessGroupId, tenantId } = urlHelpers.getTenantIdAndBusinessGroupIdFromUrl();

  const handleOnLogin = async () => {
    const { tenant_id, username, password } = loginForm.getFieldsValue();
    const body = {
      // realm: "string",
      client_secret: tenantSecret,
      scope: 'openid-connect',
      username: username,
      password: password,
      grant_type: 'password'
    };
    const { data, errors } = await loggingIn.getLogin(body, tenant_id, businessGroupId);
    if (_.isEmpty(errors)) {
      setIsLoggedIn(true);
      setAccessToken(data.access_token);
      //!We need to set tenant id and business group id  ->

      setTenent(tenant_id);
      //!This is to be set using sub domain
      setBussinessGroupName(businessGroupId);

      navigate(0);
      displaySuccessNotification({ message: 'Login Success' });
      navigate('/');
    } else {
      displayErrorNotifications(errors);
    }
  };

  React.useEffect(() => {
    if (isLoggedIn) navigate('/');
  }, [isLoggedIn]);

  React.useEffect(() => {
    setUserLocale(locale);
  }, []);

  const selectLocale = (
    <div className="absolute right-[40px] top-[40px] login-language-dropdown">
      <TransparentSelect
        value={'IN'}
        onChange={(value: any) => {
          i18n.changeLanguage(value);
          setUserLocale(value);
        }}
        options={localeEjjeList}
        style={{ width: 'fit-content' }}
        disabled={ENV !== 'DEVELOPMENT'}
        className="text-white"
        dropdownStyle={{
          backgroundColor: 'transparent'
        }}
        defaultValue={'IN'}
      />
    </div>
  );

  return (
    <>
      <div className="main">
        <div className="base_container">
          <div className="cartoon_frame">
            <div>{selectLocale}</div>
            <div className="h-[100%] flex flex-row justify-center items-center">
              <Card className="xl:w-[400px] lg:w-[400px] md:w-[400px] sm:w-[33%]">
                <div className="flex flex-col items-center pb-6">
                  <img src={images.newcbtlogo} alt="cbt_logo" className=" h-[70px] object-contain" />
                  <div className="mt-1">
                    <span className="font-extrabold text-[18px] text-[#008080]" translate="no">
                      CLOVER BAY LABS
                    </span>
                  </div>
                </div>
                <Form form={loginForm} layout="vertical" onFinish={handleOnLogin}>
                  <Form.Item
                    label={<span className="text-[#5A607F]">{t('retailercode')}</span>}
                    name={'tenant_id'}
                    initialValue={tenantId}
                    required
                    className="mb-2"
                    rules={[{ required: true, message: t('thisFieldCantBeEmpty') }]}
                  >
                    <Input
                      placeholder={t('retailercode')}
                      size="large"
                      prefix={<UserOutlined style={{ color: '#1890FF' }} />}
                    />
                  </Form.Item>
                  <Form.Item
                    label={<span className="text-[#5A607F]">{t('Username')}</span>}
                    name={'username'}
                    required
                    className="mb-2"
                    rules={[{ required: true, message: t('thisFieldCantBeEmpty') }]}
                  >
                    <Input
                      placeholder={t('Username')}
                      size="large"
                      prefix={<UserOutlined style={{ color: '#1890FF' }} />}
                    />
                  </Form.Item>
                  <Form.Item
                    label={<span className="text-[#5A607F]">{t('Password')}</span>}
                    name={'password'}
                    className="mb-0"
                    required
                    rules={[{ required: true, message: t('thisFieldCantBeEmpty') }]}
                  >
                    <Input.Password
                      type="password"
                      placeholder={t('Password')}
                      size="large"
                      prefix={<LockOutlined style={{ color: '#1890FF' }} />}
                    />
                  </Form.Item>
                  {ENV !== 'BETA' && (
                    <div
                      className="flex items-center justify-end mb-4 underline text-[#0011ff]"
                      style={{ cursor: 'pointer' }}
                      onClick={() => navigate('/login/forgot-password')}
                    >
                      {t('forgotPassword')}?
                    </div>
                  )}
                  <div className="mt-4">
                    <Button type="primary" block size="large" htmlType="submit">
                      <BoldButtonLabel labelText={t('signin')} />
                    </Button>
                  </div>
                </Form>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
