import { Button, Card, Col, Form, Input, Row, Select, Typography } from 'antd';
import DmnModeler from 'dmn-js/lib/Modeler';
import * as React from 'react';
import TenantInfo from '../../../components/TenantIdInfo';
import PrimaryLayout from '../../../layouts/primary-layout';
import { useRewardNames } from '../hooks/use-reward-names';

import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import _ from '../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../helpers/toast.helpers';
import { loyaltyService } from '../services/loyalty.service';
import '../styles/custom-create-point-conversion.scoped.css';

interface ICreatePointConversionRuleProps {}

const CreatePointConversionRule: React.FunctionComponent<ICreatePointConversionRuleProps> = props => {
  const { rewardNameSelectOptions } = useRewardNames();
  const [tableData, setTableData] = React.useState<any>({});

  let modeller: any = null;

  const modellerRef = React.useRef<any>(null);

  const initialXML =
    '<?xml version="1.0" encoding="UTF-8"?>\r\n<definitions xmlns="https://www.omg.org/spec/DMN/20191111/MODEL/" id="definitions_07uqaar" name="definitions" namespace="http://camunda.org/schema/1.0/dmn" exporter="dmn-js (https://demo.bpmn.io/dmn)" exporterVersion="14.1.1">\r\n  <decision id="decision_11mjt9j" name="Point Conversion Rule">\r\n    <decisionTable id="decisionTable_0k1wdl7">\r\n      <input id="input1" label="Customer Tier">\r\n        <inputExpression id="inputExpression1" typeRef="string">\r\n          <text>customer.tier_type</text>\r\n        </inputExpression>\r\n      </input>\r\n      <output id="output1" label="Factor" name="factor" typeRef="number" />\r\n    </decisionTable>\r\n  </decision>\r\n</definitions>';

  React.useEffect(() => {
    loadIntialTable();
    // if (modeller === null) initializeDiagram(initialXML);
    return () => {
      if (modeller !== null) {
        modeller.detach();
        modeller = null;
      }
    };
  }, []);

  React.useEffect(() => {
    initializeDiagram();
  }, [tableData]);

  const loadIntialTable = async () => {
    setLoading(true);
    const bodyData = {
      rule_type: 'POINT'
    };
    const { data, errors } = await loyaltyService.getRuleTable(bodyData);
    if (_.isEmpty(errors)) {
      setTableData(data);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const initializeDiagram = async () => {
    modeller = new DmnModeler({
      container: '#pointConversionCanvas'
    });
    modellerRef.current = modeller;
    console.log('tableData', tableData);
    let bodyData = {
      ...tableData
    };
    try {
      //   const { warnings } = await modeller.importXML(initialXML);
      const { warnings } = await modeller.importXML(bodyData?.xml);
      if (warnings.length) {
        console.log('import with warnings', warnings);
      } else {
        console.log('import successful');
      }
    } catch (err) {
      console.log('something went wrong:', err);
    }
  };

  const [loading, setLoading] = React.useState(false);

  const handleCreateConversionRule = async () => {
    setLoading(true);
    const { reward_id, name } = createForm.getFieldsValue();

    const { xml } = await modellerRef.current.saveXML();
    if (!xml.includes('<inputEntry')) {
      displayErrorNotifications([{ message: t('pleaseAddAtLeastOneRuleCondition') }]);
    } else {
      const request = {
        reward_id,
        xml,
        name
      };
      const { errors } = await loyaltyService.createPointRule(request);
      if (_.isEmpty(errors)) {
        displaySuccessNotification({ message: t('pointConversionRuleCreated') });
        navigate(`/loyalty/config/point-conversion?reward_id=${reward_id}&offset=0`);
      } else {
        displayErrorNotifications(errors);
      }
    }

    setLoading(false);
  };

  const navigate = useNavigate();

  const [createForm] = Form.useForm();

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                {t('pointConversionRule')}
              </Typography.Title>
            </Col>
          </Row>
          <div className="flex gap-4">
            <TenantInfo />
            <Typography.Text className="text-[#898c98]">
              {t('rewardType')}- <span className="font-bold text-black">{t('points')}</span>{' '}
            </Typography.Text>
          </div>
          <section className="mt-4">
            <Form layout="vertical" form={createForm} onFinish={handleCreateConversionRule}>
              <>
                <Row gutter={12}>
                  <Col xs={24} md={6}>
                    <Form.Item
                      name="reward_id"
                      rules={[{ message: t('rewardNameIsRequired'), required: false }]}
                      label={t('rewardName')}
                    >
                      <Select placeholder={t('selectRewardType')} size="large" options={rewardNameSelectOptions} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={6}>
                    <Form.Item
                      rules={[{ message: t('ruleNameIsRequired'), required: true }]}
                      name="name"
                      label={t('conversionRuleName')}
                    >
                      <Input size="large" placeholder={t('enterRuleName')} />
                    </Form.Item>
                  </Col>
                </Row>
                {Object.keys(tableData).length > 0 && <div id="pointConversionCanvas" className="h-[400px]"></div>}
                <Row gutter={12} className="mt-4">
                  <Col xs={24} md={8} lg={6}>
                    {/* loading={loading} */}
                    <Button block htmlType="submit" size="large" type="primary">
                      <BoldButtonLabel labelText={t('create')} />
                    </Button>
                  </Col>
                  <Col xs={24} md={8} lg={6}>
                    <Button
                      block
                      size="large"
                      onClick={() => {
                        setTableData({});
                        navigate(`/loyalty/config/point-conversion`);
                      }}
                    >
                      <BoldButtonLabel labelText={t('back')} />
                    </Button>
                  </Col>
                </Row>{' '}
              </>
            </Form>{' '}
          </section>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default CreatePointConversionRule;
