import { useForm } from 'antd/es/form/Form';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { IProfileDetailsResponse } from '../../../../types/profile.types';
import { Card, Col, Divider, Form, InputNumber, Row, Switch, Tabs, Tooltip, Typography } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

interface IFulfillmentSettings {
  active: boolean;
  profileDetails: IProfileDetailsResponse;
}

const FulfillmentSettings: React.FunctionComponent<IFulfillmentSettings> = ({ active, profileDetails }) => {
  const [configForm] = useForm();
  const { t } = useTranslation();

  React.useEffect(() => {
    if (active) {
      populateConfigForm();
    }
  }, [active, profileDetails]);

  const populateConfigForm = () => {
    const {
      ignore_shipment_consolidation,
      local_delivery_max_distance_radius,
      retry_intervals,
      cancel_order_for_inventory_shortage,
      constraints,
      inventory_control,
      optimization,
      priorities,
      reservations
    } = profileDetails?.tenant_details?.subscriptions?.fulfillment_settings || {};
    const { back_ordered_in_hrs, no_of_retries_allowed, back_ordered_in_min } = retry_intervals || {};
    const {
      allow_max_shipment_delay_window,
      combine_with_future_inventory,
      item_line_ship_complete,
      item_line_ship_from_single_node,
      maximum_release_lead_days,
      ship_complete,
      ship_from_single_node,
      ship_when_they_are_available,
      fulfil_bundle_products_from_same_node,
      allow_fulfillment_option_selection,
      allow_return_fulfillment_option_selection,
      is_customer_collection_point_allowed,
      customer_pickup_max_distance_radius_in_km
    } = constraints || {};

    const {
      apply_future_safety_factor,
      apply_onhand_safety_factor,
      consider_future_inventory_inquiry,
      consider_future_inventory_sales
    } = inventory_control || {};

    const { allow_item_reservation } = reservations;
    const {
      by_delivery_date,
      by_landed_cost,
      by_number_of_shipments,
      by_priority,
      by_ship_to_distance,
      show_courier_option,
      max_sourcing_dg_group,
      check_availability_first
    } = optimization;
    const {
      distance_weightage_factor,
      node_priority_based,
      node_priority_factor_multiplier,
      node_weightage_factor,
      ship_to_distance_based
    } = priorities;
    let back_ordered_in_min_new = back_ordered_in_min ? back_ordered_in_min : 120;
    const formValues = {
      distance_weightage_factor,
      node_priority_based,
      node_priority_factor_multiplier,
      node_weightage_factor,
      ship_to_distance_based,
      by_delivery_date,
      by_landed_cost,
      by_number_of_shipments,
      by_priority,
      by_ship_to_distance,
      allow_item_reservation,
      apply_future_safety_factor,
      apply_onhand_safety_factor,
      consider_future_inventory_inquiry,
      consider_future_inventory_sales,
      allow_max_shipment_delay_window,
      combine_with_future_inventory,
      item_line_ship_complete,
      item_line_ship_from_single_node,
      maximum_release_lead_days,
      ship_complete,
      ship_from_single_node,
      ship_when_they_are_available,
      back_ordered_in_hrs: back_ordered_in_min_new,
      ignore_shipment_consolidation,
      local_delivery_max_distance_radius,
      fulfil_bundle_products_from_same_node,
      show_courier_option,
      max_sourcing_dg_group,
      check_availability_first,
      cancel_order_for_inventory_Shortage: false,
      no_of_retries_allowed,
      reservationExpeirryInMins: 30,
      allow_fulfillment_option_selection,
      local_delivery_max_distance_radius_in_KM: 10,
      allow_return_fulfillment_option_selection,
      is_customer_collection_point_allowed,
      customer_pickup_max_distance_radius_in_km
    };

    configForm.setFieldsValue(formValues);
  };

  return (
    <Form form={configForm} layout="vertical" disabled className="nebula-config">
      <Card className="rounded-none">
        <h2 className="text-base text-gray-800 p-4 pl-6 country-details">{t('fulfillmentSettings')}</h2>
      </Card>

      <Card className="mt-6 p-0">
        <h2 className="text-base text-gray-800 p-4 pl-6 country-details">{t('constraints')}</h2>
        <Divider className="bg-[#ffffff]" />

        <Row gutter={[12, 12]} className="p-6">
          <Col xs={12} md={6} className="flex">
            <Tooltip placement="top" title={t('dispatchTheEntireOrderAtOnce')}>
              <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />
              &nbsp;&nbsp;
            </Tooltip>
            <Form.Item label={t('shipCompletely')} valuePropName="checked" name="ship_complete">
              <Switch checkedChildren={t('yes')} unCheckedChildren={t('no')} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6} className="flex">
            <Tooltip placement="top" title={t('sendEachItemFromTheSameLocation')}>
              <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />
              &nbsp;&nbsp;
            </Tooltip>
            <Form.Item label={t('Item Line Ship Complete')} valuePropName="checked" name="item_line_ship_complete">
              <Switch checkedChildren={t('yes')} unCheckedChildren={t('no')} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6} className="flex">
            <Tooltip placement="top" title={t('sendEachItemFromTheSameLocation')}>
              <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />
              &nbsp;&nbsp;
            </Tooltip>
            <Form.Item label={t('Ship From Single Node')} valuePropName="checked" name="ship_from_single_node">
              <Switch checkedChildren={t('yes')} unCheckedChildren={t('no')} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6} className="flex">
            <Tooltip placement="top" title={t('shipLineItemFromSingleNode')}>
              <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />
              &nbsp;&nbsp;
            </Tooltip>
            <Form.Item
              label={t('shipLineItemFromSingleNode')}
              valuePropName="checked"
              name="item_line_ship_from_single_node"
            >
              <Switch checkedChildren={t('yes')} unCheckedChildren={t('no')} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6} className="flex">
            <Tooltip placement="top" title={t('AllowFulfilmentOptionSelection')}>
              <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />
              &nbsp;&nbsp;
            </Tooltip>
            <Form.Item
              label={t('AllowFulfilmentOptionSelection')}
              valuePropName="checked"
              name="allow_fulfillment_option_selection"
            >
              <Switch checkedChildren={t('yes')} unCheckedChildren={t('no')} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6} className="flex">
            <Tooltip placement="top" title={t('AllowReturnFulfillmentOptionSelection')}>
              <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />
              &nbsp;&nbsp;
            </Tooltip>
            <Form.Item
              label={t('AllowReturnFulfillmentOptionSelection')}
              valuePropName="checked"
              name="allow_return_fulfillment_option_selection"
            >
              <Switch checkedChildren={t('yes')} unCheckedChildren={t('no')} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6} className="flex">
            <Tooltip placement="top" title={t('IsCustomerCollectionPointAllowed')}>
              <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />
              &nbsp;&nbsp;
            </Tooltip>
            <Form.Item
              label={t('IsCustomerCollectionPointAllowed')}
              valuePropName="checked"
              name="is_customer_collection_point_allowed"
            >
              <Switch checkedChildren={t('yes')} unCheckedChildren={t('no')} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6} className="flex">
            <Tooltip placement="top" title={t('CustomerPickupMaxDistanceinKM')}>
              <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />
              &nbsp;&nbsp;
            </Tooltip>
            <Form.Item label={t('CustomerPickupMaxDistanceinKM')} name={'customer_pickup_max_distance_radius_in_km'}>
              <InputNumber className="w-full" min={0} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6} className="flex">
            <Tooltip placement="top" title={t('maximumReleaseLeadDays')}>
              <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />
              &nbsp;&nbsp;
            </Tooltip>
            <Form.Item label={t('maximumReleaseLeadDays')} name={'maximum_release_lead_days'}>
              <InputNumber className="w-full" min={0} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6} className="flex">
            <Tooltip placement="top" title={t('maxShipmentDelayWindow')}>
              <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />
              &nbsp;&nbsp;
            </Tooltip>
            <Form.Item label={t('maxShipmentDelayWindow')} name="allow_max_shipment_delay_window">
              <InputNumber className="w-full" min={0} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6} className="flex">
            <Tooltip placement="top" title={t('LocaldeliverymaxdistanceradiusinKM')}>
              <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />
              &nbsp;&nbsp;
            </Tooltip>
            <Form.Item label={t('LocaldeliverymaxdistanceradiusinKM')} name="local_delivery_max_distance_radius_in_KM">
              <InputNumber className="w-full" min={0} />
            </Form.Item>
          </Col>
          {/* ------------ */}
        </Row>
      </Card>

      <Card className="mt-6">
        <h2 className="text-base text-gray-800 p-4 pl-6 country-details">{t('inventoryControl')}</h2>
        <Divider className="bg-[#ffffff]" />
        <Row gutter={[12, 12]} className="p-6">
          <Col xs={12} md={6} className="flex">
            <Tooltip placement="top" title={t('considerFutureInventoryEnquiry')}>
              <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />
              &nbsp;&nbsp;
            </Tooltip>
            <Form.Item
              label={t('considerFutureInventoryEnquiry')}
              name={'consider_future_inventory_inquiry'}
              valuePropName="checked"
            >
              <Switch checkedChildren={t('yes')} unCheckedChildren={t('no')} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6} className="flex">
            <Tooltip placement="top" title={t('ConsiderFutureInventorySales')}>
              <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />
              &nbsp;&nbsp;
            </Tooltip>
            <Form.Item
              label={t('ConsiderFutureInventorySales')}
              valuePropName="checked"
              name="consider_future_inventory_sales"
            >
              <Switch checkedChildren={t('yes')} unCheckedChildren={t('no')} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6} className="flex">
            <Tooltip placement="top" title={t('Applyfuturesafetyfactor')}>
              <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />
              &nbsp;&nbsp;
            </Tooltip>
            <Form.Item label={t('Applyfuturesafetyfactor')} valuePropName="checked" name="apply_future_safety_factor">
              <Switch checkedChildren={t('yes')} unCheckedChildren={t('no')} />
            </Form.Item>
          </Col>
        </Row>
      </Card>

      <Card className="mt-6">
        <h2 className="text-base text-gray-800 p-4 pl-6 country-details">{t('optimizeBy')}</h2>
        <Divider className="bg-[#ffffff]" />
        <Row gutter={[12, 12]} className="p-6">
          <Col xs={12} md={6} className="flex">
            <Tooltip placement="top" title={t('arrangeShipmentsBasedOnPriorityLevels')}>
              <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />
              &nbsp;&nbsp;
            </Tooltip>
            <Form.Item label={t('priority')} name={'by_priority'}>
              <InputNumber className="w-full" min={0} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6} className="flex">
            <Tooltip placement="top" title={t('prioritizeShippingBasedOnDeliveryDistance')}>
              <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />
              &nbsp;&nbsp;
            </Tooltip>
            <Form.Item label={t('shipToDistance')} name={'by_ship_to_distance'}>
              <InputNumber className="w-full" min={0} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6} className="flex">
            <Tooltip placement="top" title={t('organizeShipmentsToMeetDeliveryDeadlines')}>
              <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />
              &nbsp;&nbsp;
            </Tooltip>
            <Form.Item label={t('deliveryDates')} name={'by_delivery_date'}>
              <InputNumber className="w-full" min={0} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6} className="flex">
            <Tooltip placement="top" title={t('numberOfShipments')}>
              <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />
              &nbsp;&nbsp;
            </Tooltip>
            <Form.Item label={t('numberOfShipments')} name={'by_number_of_shipments'}>
              <InputNumber className="w-full" min={0} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[12, 12]} className="p-6">
          <Col xs={12} md={6} className="flex">
            <Tooltip placement="top" title={t('optimizeBasedOnTotalShippingCosts')}>
              <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />
              &nbsp;&nbsp;
            </Tooltip>
            <Form.Item label={t('landedCosts')} name={'by_landed_cost'}>
              <InputNumber className="w-full" min={0} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6} className="flex">
            <Tooltip placement="top" title={t('useTheMaximumNumberOfSourcesForFulfillment')}>
              <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />
              &nbsp;&nbsp;
            </Tooltip>
            <Form.Item label={t('maxSourcing')} name={'max_sourcing_dg_group'}>
              <InputNumber className="w-full" min={0} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6} className="flex">
            <Tooltip placement="top" title={t('prioritizeItemsThatAreCurrentlyInStock')}>
              <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />
              &nbsp;&nbsp;
            </Tooltip>
            <Form.Item label={t('checkAvailabilityFirst')} name={'check_availability_first'} valuePropName="checked">
              <Switch checkedChildren={t('yes')} unCheckedChildren={t('no')} />
            </Form.Item>
          </Col>
        </Row>
      </Card>

      <Card className="mt-6">
        <h2 className="text-base text-gray-800 p-4 pl-6 country-details">{t('nodePriorities')}</h2>
        <Divider className="bg-[#ffffff]" />
        <Row gutter={[12, 12]} className="p-6">
          <Col xs={12} md={6} className="flex">
            <Tooltip placement="top" title={t('factorInDistanceWhenPrioritizingNodes')}>
              <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />
              &nbsp;&nbsp;
            </Tooltip>
            <Form.Item label={t('distanceWeightageFactor')} name={'distance_weightage_factor'}>
              <InputNumber className="w-full" min={0} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6} className="flex">
            <Tooltip placement="top" title={t('weightNodesBasedOnSpecificCriteria')}>
              <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />
              &nbsp;&nbsp;
            </Tooltip>
            <Form.Item label={t('nodeWeightageFactor')} name={'node_weightage_factor'}>
              <InputNumber className="w-full" min={0} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6} className="flex">
            <Tooltip placement="top" title={t('multiplyNodePriorityFactorsForImpact')}>
              <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />
              &nbsp;&nbsp;
            </Tooltip>
            <Form.Item label={t('nodePriorityFactorMultiplier')} name={'node_priority_factor_multiplier'}>
              <InputNumber className="w-full" min={0} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6} className="flex">
            <Tooltip placement="top" title={t('shipToDistanceBased')}>
              <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />
              &nbsp;&nbsp;
            </Tooltip>
            <Form.Item label={t('shipToDistanceBased')} name={'ship_to_distance_based'} valuePropName="checked">
              <Switch checkedChildren={t('yes')} unCheckedChildren={t('no')} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6} className="flex">
            <Tooltip placement="top" title={t('rankNodesBasedOnAssignedPriority')}>
              <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />
              &nbsp;&nbsp;
            </Tooltip>
            <Form.Item label={t('nodePriorityBased')} name={'node_priority_based'} valuePropName="checked">
              <Switch checkedChildren={t('yes')} unCheckedChildren={t('no')} />
            </Form.Item>
          </Col>
        </Row>
      </Card>

      <Card className="mt-6">
        <h2 className="text-base text-gray-800 p-4 pl-6 country-details">{t('reservations')}</h2>
        <Divider className="bg-[#ffffff]" />
        <Row gutter={[12, 12]} className="p-6">
          <Col xs={12} md={6} className="flex">
            <Form.Item label={t('reservationExpeiry')} name={'reservationExpeirryInMins'}>
              <InputNumber className="w-full" min={0} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6} className="flex">
            <Tooltip placement="top" title={t('allowItemReservations')}>
              <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />
              &nbsp;&nbsp;
            </Tooltip>
            <Form.Item label={t('allowItemReservations')} name={'allow_item_reservation'} valuePropName="checked">
              <Switch checkedChildren={t('yes')} unCheckedChildren={t('no')} />
            </Form.Item>
          </Col>
        </Row>
      </Card>

      <Card className="mt-6">
        <h2 className="text-base text-gray-800 pl-6 p-4 country-details">{t('Order & Inventory Handling')}</h2>
        <Divider className="bg-[#ffffff]" />
        <Row gutter={[12, 12]} className="pt-6 pl-6">
          <Col xs={12} md={6} className="flex">
            <Tooltip placement="top" title={t('cancelOrdersIfStockIsInsufficient')}>
              <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />
              &nbsp;&nbsp;
            </Tooltip>
            <Form.Item
              label={t('cancelOrderForInventoryShortage')}
              name={'cancel_order_for_inventory_shortage'}
              valuePropName="checked"
            >
              <Switch checkedChildren={t('yes')} unCheckedChildren={t('no')} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6} className="flex">
            <Tooltip placement="top" title={t('skipCombiningMultipleShipments')}>
              <InfoCircleOutlined style={{ color: '#1b6ef3', marginBottom: '65px' }} />
              &nbsp;&nbsp;
            </Tooltip>
            <Form.Item
              label={t('ignoreShipmentConsolidation')}
              name={'ignore_shipment_consolidation'}
              valuePropName="checked"
            >
              <Switch checkedChildren={t('yes')} unCheckedChildren={t('no')} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6} className="flex">
            <Form.Item label={t('PromiseExpiryInMinutes')} name={'reservationExpeirryInMins'}>
              <InputNumber className="w-full" min={0} />
            </Form.Item>
          </Col>
        </Row>
      </Card>

      <Card className="mt-6">
        <h2 className="text-base text-gray-800 pl-6 p-4 country-details">{t('retryIntervals')}</h2>
        <Divider className="bg-[#ffffff]" />
        <Row gutter={[12, 12]} className="p-6">
          <Col xs={12} md={6} className="flex">
            <Form.Item label={t('backOrderedInHours')} name={'back_ordered_in_hrs'}>
              <InputNumber className="w-full" min={0} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6} className="flex">
            <Form.Item label={t('numberOfRetryAllowed')} name={'no_of_retries_allowed'}>
              <InputNumber className="w-full" min={0} />
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </Form>
  );
};

export default FulfillmentSettings;
