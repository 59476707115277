import { CloudDownloadOutlined } from '@ant-design/icons';
import { Table, TableColumnsType } from 'antd';
import dayjs from 'dayjs';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { fileHelpers, objectHelpers } from '../../../../helpers';
import _ from '../../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../../helpers/toast.helpers';
import { profileService } from '../../../../services';
import { useLoader } from '../../../../stores/use-loader';

interface IPromoUploadReport {
  active: boolean;
}

const PromoUploadReport: React.FunctionComponent<IPromoUploadReport> = ({ active }) => {
  const { t } = useTranslation();

  const [reportListing, setReportListing] = React.useState({} as any);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageControl, setPageControl] = React.useState(() => {
    const offset = parseInt(searchParams.get('offset') || '0');
    const limit = parseInt(searchParams.get('limit') || '10');
    return {
      currentPage: offset > 0 ? offset + 1 : 1,
      pageSize: limit > 0 ? limit : 10
    };
  });

  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));

  const bulk_upload_report_columns: TableColumnsType<any> = [
    // {
    //   title: t('fileId'),
    //   align: 'center',
    //   render(value: any, record: any, index: any) {
    //     return (
    //       <>
    //         <div>{record?.id}</div>
    //       </>
    //     );
    //   }
    // },
    {
      title: t('fileName'),
      align: 'center',
      width: 70,
      render(value: any, record: any, index: any) {
        return (
          <>
            <div>{record?.file_name}</div>
          </>
        );
      }
    },
    {
      title: t('createdAt'),
      align: 'center',
      render(value: any, record: any, index: any) {
        let date = record?.file_upload_timestamp ? dayjs(record?.file_upload_timestamp).format('DD/MM/YYYY') : '';
        let time = record?.file_upload_timestamp ? dayjs(record?.file_upload_timestamp).format('HH:mm:ss') : '';
        return (
          <>
            {date} {time}
          </>
        );
      }
    },
    {
      title: t('status'),
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.status}</>;
      }
    },
    {
      title: t('totalRecords'),
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.total_records}</>;
      }
    },
    {
      title: t('successCount'),
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.success_records}</>;
      }
    },
    {
      title: t('failureCount'),
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.failure_records}</>;
      }
    },
    {
      title: t('Download'),
      align: 'center',
      render(value: any, record: any, index: any) {
        return (
          <>
            {
              <CloudDownloadOutlined
                style={{
                  fontSize: '32px',
                  color: record?.status === 'PROCESSED' ? '#1890ff' : '#c0c0c0',
                  cursor: record?.status === 'PROCESSED' ? 'pointer' : 'not-allowed'
                }}
                onClick={() => {
                  if (record?.status === 'PROCESSED') {
                    handleOnDownloadSingleReport(record);
                  }
                }}
              />
            }
          </>
        );
      }
    }
  ];

  React.useEffect(() => {
    if (active) {
      loadIntialData();
    }
  }, [active, pageControl]);

  const loadIntialData = async () => {
    setLoading(true);
    let params = { offset: pageControl?.currentPage - 1, limit: pageControl?.pageSize };
    const filteredParams = objectHelpers.deleteUndefinedValuesFromObject(params);
    setSearchParams(filteredParams);
    const { data, errors } = await profileService.getReportOfBulkUpload(filteredParams);
    if (_.isEmpty(errors)) {
      setReportListing(data);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleOnDownloadSingleReport = async (record: any) => {
    setLoading(true);
    const header = {
      'x-file-event-type': record?.upload_type
    };
    const { data, headers, errors } = await profileService.downloadIndivisualReport(record?.id, header);

    if (_.isEmpty(errors)) {
      const fName = headers?.['x-file-name'] || 'Sample Template.xlsx';
      const fType = '.' + headers?.['x-file-type'] || 'xlsx';
      fileHelpers.triggerFileDownload({ data, fileName: fName.split('.')[0], extension: fType });
      displaySuccessNotification({ message: 'File Downloaded successfully!' });
    }
    setLoading(false);
  };

  return (
    <div>
      <section className="mt-4">
        <Table
          bordered
          className="mt-4"
          dataSource={reportListing?.jobs || []}
          columns={bulk_upload_report_columns}
          scroll={{ x: 1000 }}
          pagination={{
            current: pageControl?.currentPage,
            total: reportListing?.page_info?.total_pages * pageControl?.pageSize || 0,
            pageSize: pageControl?.pageSize,
            showSizeChanger: true,
            pageSizeOptions: ['1', '10', '20', '50', '100'],
            onChange: (currentPage, pageSize) => {
              setPageControl({ currentPage, pageSize });
            },
            locale: {
              items_per_page: `${t('page')}`
            }
          }}
          locale={{
            emptyText: t('noData')
          }}
        />
      </section>
    </div>
  );
};

export default PromoUploadReport;
