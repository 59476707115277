import { Card, Form, Row, Typography } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import TenantInfo from '../../../components/TenantIdInfo';
import _ from '../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../helpers/toast.helpers';
import PrimaryLayout from '../../../layouts/primary-layout';
import { profileService } from '../../../services';
import { useLoader } from '../../../stores/use-loader';
import ManageUserForm from './manage-user-form';

const EditUser: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { setLoading } = useLoader();
  const [createUserForm] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams({ user_name: '' });
  const [t] = useTranslation();
  const productList: { label: string; value: string }[] = [
    { label: t('opus'), value: 'opus' },
    { label: t('elysian'), value: 'elysian' },
    { label: t('nebula'), value: 'nebula' },
    { label: t('identity'), value: 'identity' }
  ];

  const roleList: { label: string; value: string }[] = [
    { label: t('root'), value: 'ROOT' },
    { label: t('readOnly'), value: 'Read Only' }
  ];

  React.useEffect(() => {
    if (searchParams.get('user_name')) {
      fetchUser();
    }
  }, [searchParams]);

  const fetchUser = async () => {
    setLoading(true);
    const userName = searchParams.get('user_name') as string;
    if (!userName) return;
    const { data, errors } = await profileService.getUser(userName);
    if (_.isEmpty(errors)) {
      const first_name = data.first_name;
      const formatData = {
        ...data,
        first_name: first_name.split(' ')[0],
        middle_name: first_name.split(' ')[1] || ''
      };
      createUserForm.setFieldsValue(formatData);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleOnSave = async () => {
    setLoading(true);
    try {
      const values = createUserForm.getFieldsValue();
      const userName = searchParams.get('user_name') as string;

      const { errors } = await profileService.updateUser(userName, values);

      if (_.isEmpty(errors)) {
        displaySuccessNotification({ message: t('userUpdatedSuccessfully') });
        navigate(-1);
      } else {
        displayErrorNotifications(errors);
      }
    } catch (error: any) {
      displayErrorNotifications([error?.message || t('unexpectedErrorOccurred')]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PrimaryLayout>
      <div className="w-full mx-auto px-4">
        <Card>
          <Row gutter={12} justify={'space-between'}>
            <Typography.Title level={3} className="text-[#2e2a5b] pb-4">
              <div className="pb-2 pt-2">{t('editUsers')}</div>
              <TenantInfo />
            </Typography.Title>
          </Row>

          <ManageUserForm formType={'edit'} formInstance={createUserForm} handleOnSave={handleOnSave} />
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default EditUser;
