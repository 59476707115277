import { CloudUploadOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Row, Select, Table, TableColumnsType, Typography } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { fileHelpers } from '../../../../helpers';
import _ from '../../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../../helpers/toast.helpers';
import { profileService } from '../../../../services';
import { useLoader } from '../../../../stores/use-loader';

interface IPromoBulkUpload {
  active: boolean;
}

const PromoBulkUpload: React.FunctionComponent<IPromoBulkUpload> = ({ active }) => {
  const [formInstance] = Form.useForm();
  const { t } = useTranslation();

  const [fileList, setFileList] = React.useState<any[]>([]);
  const [uploadTypeOptions, setUploadTypeOptions] = React.useState<any[]>([]);
  const [fileUploadResponse, setFileUploadResponse] = React.useState<any[]>([]);

  const { setLoading } = useLoader(({ loading, setLoading }) => ({ loading, setLoading }));

  const bulk_upload_columns: TableColumnsType<any> = [
    {
      title: t('rowNumber'),
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.row_number}</>;
      }
    },
    {
      title: t('columnName'),
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.column_name}</>;
      }
    },
    {
      title: t('description'),
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.description}</>;
      }
    },
    {
      title: t('required'),
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.required.toString()}</>;
      }
    }
  ];

  React.useEffect(() => {
    if (active) {
      loadIntialData();
      formInstance.setFieldsValue({
        file_type: undefined
      });
      setFileUploadResponse([]);
    }
  }, [active]);

  const loadIntialData = async () => {
    setLoading(true);
    const { data, errors } = await profileService.getUploadTypes();
    if (_.isEmpty(errors)) {
      let formatedData = data.upload_types.map((item: any) => {
        let upload_type_label = item.upload_type;
        if (upload_type_label === 'eopus-customer-feed') {
          upload_type_label = 'Customer Details';
        } else if (upload_type_label === 'eopus-sales-feed') {
          upload_type_label = 'Sales Details';
        }
        return {
          label: upload_type_label,
          value: item.upload_type,
          service_name: item.service_name
        };
      });
      setUploadTypeOptions(formatedData);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleOnSelectfileSelect = (selectedKeyword: any, selectedKeyObj: any) => {
    getTableAttributes(selectedKeyword);
    formInstance.setFieldsValue({
      service_name: selectedKeyObj?.service_name
    });
  };

  const handleOnDownloadTemplate = async () => {
    setLoading(true);
    const { file_type } = formInstance.getFieldsValue();
    const { data, headers, errors } = await profileService.getTemplate(file_type);

    if (_.isEmpty(errors)) {
      const fName = headers?.['x-file-name'] || 'Sample Template.xlsx';
      const fType = '.' + headers?.['x-file-type'] || 'xlsx';
      fileHelpers.triggerFileDownload({ data, fileName: fName.split('.')[0], extension: fType });
      // getTableAttributes(file_type);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const getTableAttributes = async (file_type: any) => {
    setLoading(true);
    const { data, errors } = await profileService.getFieldDetails(file_type);
    if (_.isEmpty(errors)) {
      setFileUploadResponse(data?.field_details);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleOnClickOnUpload = async () => {
    setLoading(true);

    if (!fileList.length) {
      displayErrorNotifications([{ message: 'Please select a file to upload!' }]);
      return;
    }

    const { file_type } = formInstance.getFieldsValue();
    const file = fileList[0];

    const formData = new FormData();
    formData.append('file', file);
    const headers = {
      'x-file-event-type': file_type,
      'x-file-type': 'xlsx',
      'x-file-size': file.size.toString(),
      'x-file-upload-timestamp': new Date().toISOString()
    };

    const { data, errors } = await profileService.fileUploadToApi(headers, formData);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'File Uploaded successfully!' });
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  return (
    <section className="mt-4">
      <Form form={formInstance} layout="vertical" className="nebula-config" onFinish={handleOnDownloadTemplate}>
        <Row gutter={12}>
          <Col xs={12} md={6}>
            <Form.Item
              name="file_type"
              label={t('fileType')}
              rules={[{ required: true, message: t('thisFieldCantBeEmpty') }]}
            >
              <Select
                allowClear
                placeholder={t('fileType')}
                options={uploadTypeOptions || []}
                size="large"
                onSelect={handleOnSelectfileSelect}
                onClear={() => {
                  setFileUploadResponse([]);
                }}
              />
            </Form.Item>
          </Col>
          {/* <Col xs={12} md={6}>
            <Form.Item
              name="service_name"
              label={t('services')}
              rules={[{ required: true, message: t('thisFieldCantBeEmpty') }]}
            >
              <Input size="large" placeholder={t('services')} />
            </Form.Item>
          </Col> */}
        </Row>
        <Row gutter={12}>
          <Col>
            <Button block type="primary" htmlType="submit">
              {t('downloadTemplat')}
            </Button>
          </Col>
        </Row>
        <section className="flex flex-col justify-center mt-8">
          <Dragger
            beforeUpload={file => {
              setFileList([file]);
              return false;
            }}
            onRemove={() => {
              setFileList([]);
            }}
            fileList={fileList}
            name="file"
            accept={'.csv,.xlsx'}
          >
            <p>
              <CloudUploadOutlined className="text-9xl text-[#008080]" />
            </p>
            <p>{t('clickOrDragTemplateExcelFile')}</p>
          </Dragger>
        </section>
        <Row gutter={12} className="mt-2 flex justify-end">
          <Col xs={12} md={3}>
            <Button
              block
              type="primary"
              disabled={_.isEmpty(fileList)}
              onClick={async () => {
                try {
                  await formInstance.validateFields(['file_type']);
                  handleOnClickOnUpload();
                } catch (error) {
                  console.log('valatiateSaveHandler', error);
                }
              }}
            >
              {t('upload')}
            </Button>
          </Col>
        </Row>
        <Card className="mt-2">
          <Typography.Title level={5} className="p-4">
            {t('tableNameBulkUpload')}
          </Typography.Title>
          <section>
            <Table
              bordered
              className="mt-4"
              dataSource={fileUploadResponse || []}
              columns={bulk_upload_columns}
              scroll={{ x: 1000 }}
              // pagination={{ ...pagination, position: ['bottomRight'] }}
            />
          </section>
        </Card>
      </Form>
    </section>
  );
};

export default PromoBulkUpload;
