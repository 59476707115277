import dayjs from 'dayjs';

export const formatDateTime = (isoDate: string) => {
  const date = new Date(isoDate);
  return isoDate ? dayjs(date).format('DD-MMM-YYYY HH:mm:ss A') : '';
};
export function getLanguageCode(countryCode: string) {
  if (countryCode === 'IN') {
    return 'en'; // English for India
  } else if (countryCode === 'CL') {
    return 'es'; // Spanish for Chile
  } else {
    return 'Invalid country code';
  }
}

export const formatDate = (isoDate: string) => {
  if (!isoDate) {
    return '';
  } else {
    let curData = dayjs(new Date(isoDate)).format('DD-MM-YYYY');
    return curData;
  }
};

export const resetISOTimeStampTimeToZeroIST = (isoString: string) => {
  const date = new Date(isoString);
  date.setUTCHours(0, 0, 0, 0);
  let data = date.toISOString();
  return data;
};

export const resetISOTimeStampTimeToZeroISTCurrent = (isoString: string) => {
  const date = new Date(isoString);
  date.setUTCHours(23);
  date.setUTCMinutes(59);
  date.setUTCSeconds(59);
  date.setUTCMilliseconds(999);
  let data = date.toISOString();
  return data;
};

enum DateFilterType {
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  LAST_7_DAYS = 'LAST_7_DAYS',
  CURRENT_MONTH = 'CURRENT_MONTH',
  LAST_30_DAYS = 'LAST_30_DAYS'
}

export const selectDateFiltersData = [
  { label: 'Today', value: DateFilterType.TODAY },
  { label: 'Yesterday', value: DateFilterType.YESTERDAY },
  { label: 'Last 7 days', value: DateFilterType.LAST_7_DAYS },
  { label: 'Current Month', value: DateFilterType.CURRENT_MONTH },
  { label: 'Last 30 Days', value: DateFilterType.LAST_30_DAYS }
];

export const getIsoDateRangeByDateFilterValue = (filterValue: string): string => {
  switch (filterValue) {
    case DateFilterType.TODAY:
      const today = resetISOTimeStampTimeToZeroIST(new Date().toISOString());
      return today;
    case DateFilterType.YESTERDAY:
      return resetISOTimeStampTimeToZeroIST(dayjs().add(-1, 'day').toISOString());
    case DateFilterType.LAST_7_DAYS:
      return resetISOTimeStampTimeToZeroIST(dayjs().add(-7, 'day').toISOString());
    case DateFilterType.LAST_30_DAYS:
      return resetISOTimeStampTimeToZeroIST(dayjs().add(-30, 'day').toISOString());
    case DateFilterType.CURRENT_MONTH:
      const now = new Date();
      now.setDate(1);
      return resetISOTimeStampTimeToZeroIST(now.toISOString());

    default:
      return 'INVALID';
  }
};
export function formatTimeRange(timeRange: dayjs.Dayjs[]): string {
  const startTime = timeRange[0].format('h:mm A');
  const endTime = timeRange[1].format('h:mm A');
  return `${startTime} - ${endTime}`;
}
export function formatTimeSingle(timeRange: dayjs.Dayjs): string {
  return timeRange.format('HH:mm:ss');
}

export function formatSingleDate(date: dayjs.Dayjs): string {
  return date.format('YYYY-MM-DD');
}
interface TimeRange {
  startTime: dayjs.Dayjs;
  endTime: dayjs.Dayjs;
}

export const getDayJsRangeFromTimeRangeString = (timeRangeString: string): TimeRange => {
  const [startTimeString, endTimeString] = timeRangeString.split(' - ');

  const startTime = dayjs(startTimeString, 'h:mm A');
  const endTime = dayjs(endTimeString, 'h:mm A');

  // Check if the end time is before the start time (crossing midnight)
  if (endTime.isBefore(startTime)) {
    // Add 1 day to the end time
    endTime.add(1, 'day');
  }

  return {
    startTime,
    endTime
  };
};

export const getDayJsObjectWithFormattedString = (string: string, stringFormat: string) => {
  return dayjs(string, stringFormat);
};
