import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  FormInstance,
  Input,
  InputNumber,
  InputRef,
  Row,
  Select,
  Space,
  Spin,
  Typography
} from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';
import { stateList } from '../../../../data';
import { countryList, phoneCountryCodeList } from '../../../../data/intl-data';
import { validationHelpers } from '../../../../helpers';
import _ from '../../../../helpers/lodash';
import { displayErrorNotifications } from '../../../../helpers/toast.helpers';
import { useChannel } from '../../../promotion-engine/hooks/drop-down-hooks/use-channel';
import { useReward } from '../../../promotion-engine/hooks/drop-down-hooks/use-rewards';
import { loyaltyService } from '../../services/loyalty.service';
import dayjs from 'dayjs';
import { useLoader } from '../../../../stores/use-loader';

interface ICustomerFormProps {
  initialFormValues?: any;
  isUpdate?: boolean;
  formInstance: FormInstance<any>;
  handleFinish?: () => void;
  handleGoBack?: () => void;
}

const CustomerForm: React.FunctionComponent<ICustomerFormProps> = ({
  initialFormValues,
  formInstance,
  handleFinish,
  isUpdate,
  handleGoBack
}) => {
  const [allChannelOptions, setAllChannelOptions] = React.useState([] as any);
  const { debouncedFetchChannelDropdownOptions, channelDropdownFetching, channelDropDownOptions } = useChannel();
  const { debouncedFetchRewarsDropdownOptions, rewardDropDownFetch, rewardDropDownOptions } = useReward();
  const { t } = useTranslation();
  const [items, setItems] = React.useState(['+91', '+56']);
  const [name, setName] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const inputRef = React.useRef<InputRef>(null);
  const [inputError, setInputError] = React.useState('');
  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));
  const [rewardOption, setRewardOption] = React.useState([]);

  React.useEffect(() => {
    getChannelList();
    getRewardName();
  }, []);

  const getChannelList = async () => {
    const { data, errors } = await loyaltyService.getAllChannelList();
    if (_.isEmpty(errors)) {
      let optionData = data?.data?.map((item: any) => {
        let nameStr = `${item?.channel_name} type - ${item?.channel_type}`;
        let obj = {
          label: nameStr,
          value: nameStr,
          channel_type: item?.channel_type
        };
        return obj;
      });
      setAllChannelOptions(optionData);
    } else {
      displayErrorNotifications(errors);
    }
  };

  const getRewardName = async () => {
    setLoading(true);
    const { data, errors } = await loyaltyService.getAllRewardList();
    if (_.isEmpty(errors)) {
      let optionData = data.data.map((item: any) => {
        let obj = { label: item.name, value: item.name };
        return obj;
      });
      setRewardOption(optionData);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const disableFutureDates = (current: any) => {
    return current && current > dayjs().endOf('day');
  };

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    // Validation: "+" must be at the start, followed by digits
    if (value.startsWith('+') && /^[+\d]*$/.test(value)) {
      setInputError('');
    } else {
      setInputError(t('onlyNumberAllowedWithPlus'));
    }

    setName(value);
  };

  const addItem = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    e.preventDefault();
    if (name && !items.includes(name)) {
      setItems([...items, name]);
      formInstance.setFieldsValue({ dial_code: name });
      setName('');
      setOpen(false);
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  };

  return (
    <Form form={formInstance} onFinish={handleFinish} layout="vertical" scrollToFirstError={true}>
      <div
        style={{
          height: 'fit-content',
          background: '#9b9b9b29',
          padding: '20px',
          marginBottom: '1rem',
          borderRadius: '10px'
        }}
      >
        <Typography.Title level={4} className="text-[#2e2a5b] m-0">
          {t('basicDetails')}
        </Typography.Title>
        <Row gutter={12}>
          <Col xs={12} md={6}>
            <Form.Item
              name="customer_id"
              label={t('customerId')}
              rules={[
                {
                  required: true,
                  message: t('thisFieldIsRequired') // Show this message if the field is empty
                },
                {
                  max: 20,
                  message: t('max20Characters') // Show this if the length exceeds 20
                },
                {
                  validator: (_: any, value: string) => {
                    if (/\s/.test(value) || (value && value.trim() === '')) {
                      // Checks if there are any spaces in the value
                      return Promise.reject(new Error(t('noSpaceAllowed'))); // Add this translation key
                    }
                    return Promise.resolve();
                  }
                }
              ]}
            >
              <Input disabled={isUpdate} size="large" placeholder={t('customerId')} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              name="first_name"
              label={t('firstName')}
              rules={[
                {
                  required: true,
                  message: t('fieldIsRequired')
                },
                {
                  pattern: /^[a-zA-Z\s]+$/,
                  message: t('noSpecialCharacterAllowed')
                },
                {
                  validator: (_: any, value: string) => {
                    if (/\s/.test(value) || (value && value.trim() === '')) {
                      return Promise.reject(new Error(t('noSpaceAllowed')));
                    }
                    return Promise.resolve();
                  }
                },
                // {
                //   min: 2,
                //   message: t('min2Char')
                // },
                {
                  max: 50,
                  message: t('max50Char')
                }
              ]}
            >
              <Input size="large" placeholder={t('firstName')} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              name="last_name"
              label={t('lastName')}
              rules={[
                {
                  required: false,
                  message: t('fieldIsRequired')
                },
                {
                  pattern: /^[a-zA-Z\s]+$/,
                  message: t('noSpecialCharacterAllowed')
                },
                {
                  validator: (_: any, value: string) => {
                    if (/\s/.test(value) || (value && value.trim() === '')) {
                      return Promise.reject(new Error(t('noSpaceAllowed')));
                    }
                    return Promise.resolve();
                  }
                },
                // {
                //   min: 1,
                //   message: t('min2Char')
                // },
                {
                  max: 50,
                  message: t('max50Char')
                }
              ]}
            >
              <Input size="large" placeholder={t('lastName')} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              name="email"
              label={t('email')}
              rules={[
                {
                  required: true,
                  message: t('fieldIsRequired')
                },
                {
                  validator: (_: any, value: string) => {
                    // Regular expression for a valid email format
                    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                    // Ensuring domain part does not start/end with a hyphen
                    const domainPattern = /^[a-zA-Z0-9]+([a-zA-Z0-9-]*[a-zA-Z0-9]+)?(\.[a-zA-Z]{2,})+$/;

                    if (!value || (emailPattern.test(value) && domainPattern.test(value.split('@')[1]))) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t('invalidEmailFormat')));
                  }
                },
                {
                  max: 50,
                  message: t('max50Characters')
                }
              ]}
            >
              <Input size="large" placeholder={t('email')} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item label={t('phoneNumber')} required>
              <Input.Group compact>
                <Form.Item
                  name="dial_code"
                  noStyle
                  rules={[
                    { required: true, message: t('dialCodeIsRequired') },
                    {
                      validator: (_, value) => {
                        if (!value) {
                          return Promise.resolve();
                        }

                        // Ensure the input starts with either "00" or "+"
                        if (!(value.startsWith('00') || value.startsWith('+'))) {
                          return Promise.reject(new Error(t('dialCodeShouldStartWithPlusOr00')));
                        }

                        // If it starts with "00"
                        if (value.startsWith('00')) {
                          // Check that it's only digits after "00"
                          const validDialCode = value.slice(2);
                          if (!/^\d+$/.test(validDialCode)) {
                            return Promise.reject(new Error(t('dialCodeCanOnlyContainNumbersAfter00')));
                          }
                          // Check for max length of 6 digits (including "00")
                          if (value.length > 7) {
                            return Promise.reject(new Error(t('max4DigitsAllowed')));
                          }
                        }

                        // If it starts with "+"
                        if (value.startsWith('+')) {
                          // Check that it's only digits after "+"
                          const validDialCode = value.slice(1);
                          if (!/^\d+$/.test(validDialCode)) {
                            return Promise.reject(new Error(t('onlyNumbersAreAllowed')));
                          }
                          // Check for max length of 4 digits (including "+")
                          if (value.length > 5) {
                            return Promise.reject(new Error(t('max4DigitsAllowed')));
                          }
                        }

                        return Promise.resolve();
                      }
                    }
                  ]}
                >
                  <Input style={{ width: '30%' }} size="large" placeholder={t('dialCode')} />
                </Form.Item>

                <Form.Item
                  name="phone_number"
                  noStyle
                  rules={[
                    { required: true, message: t('phoneNumberIsRequired') },
                    {
                      validator: (_, value) => {
                        const phoneNumberPattern = /^\d{8,15}$/;
                        if (!phoneNumberPattern.test(value)) {
                          return Promise.reject(new Error(t('phoneNumberMustBeBetween8And15Digits')));
                        }
                        return Promise.resolve();
                      }
                    }
                  ]}
                >
                  <InputNumber
                    className="w-full"
                    style={{ width: '70%' }}
                    size="large"
                    placeholder={t('phoneNumber')}
                  />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>

          <Col xs={12} md={6}>
            <Form.Item name="date_of_birth" label={t('dateOfBirth')}>
              <DatePicker
                size="large"
                style={{ width: '100%' }}
                disabledDate={disableFutureDates}
                placeholder={t('selectDate')}
              />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item name="sign_up_date" label={t('signUpDate')}>
              <DatePicker
                size="large"
                className="w-full"
                disabledDate={disableFutureDates}
                placeholder={t('selectDate')}
              />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              name="customer_type"
              label={t('customerType')}
              rules={[{ required: true, message: t('customerTypeIsRequired') }]}
            >
              <Select size="large" placeholder={t('selectCustomerType')}>
                <Select.Option value="CUS">{t('customer')}</Select.Option>
                <Select.Option value="EMP">{t('employee')}</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              name="refered_by"
              label={t('referredBy')}
              rules={[
                {
                  validator: (_: any, value: string) => {
                    // If the field is empty, resolve since it's not required
                    if (!value) {
                      return Promise.resolve();
                    }

                    // Check if the field contains only spaces
                    if (value.trim() === '') {
                      return Promise.reject(new Error(t('onlyEmptySpaces')));
                    }

                    // Check if the field contains only special characters
                    const alphanumericPattern = /[A-Za-z0-9]/; // Alphanumeric character check
                    if (!alphanumericPattern.test(value)) {
                      return Promise.reject(new Error(t('onlySpecialCharValidation')));
                    }

                    return Promise.resolve();
                  }
                }
              ]}
            >
              <Input size="large" placeholder={t('enterReferredBy')} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              name="supply_type_code"
              label={t('supplyType')}
              rules={[{ required: true, message: t('thisFieldIsRequired') }]}
            >
              <Select size="large" placeholder={t('selectSupplyType')}>
                <Select.Option value="B2C">B2C</Select.Option>
                <Select.Option value="B2B">B2B</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              name="country"
              label={t('country')}
              rules={[
                {
                  required: true,
                  message: t('thisFieldIsRequired')
                },
                {
                  validator: (_, value) => {
                    if (!value) {
                      return Promise.resolve();
                    }
                    // Regular expression to allow only letters and spaces, but not a leading space
                    const countryRegex = /^[a-zA-Z][a-zA-Z\s]*$/;
                    if (countryRegex.test(value)) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(t('invalidCountry'));
                    }
                  },
                  message: t('invalidCountry') // Message when validation fails
                }
              ]}
            >
              <Input
                placeholder={t('selectCountry')}
                size="large"
                onChange={() => {
                  formInstance.setFieldsValue({ state: null });
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              noStyle
              shouldUpdate={(prev, curr) => {
                return prev.country !== curr.country;
              }}
            >
              {form => {
                return (
                  <Form.Item
                    name="state"
                    label={t('state')}
                    rules={[
                      {
                        required: true,
                        message: t('thisFieldIsRequired')
                      },
                      {
                        validator: (_, value) => {
                          if (!value) {
                            return Promise.resolve();
                          }
                          const stateRegex = /^[a-zA-Z!^(){}][a-zA-Z\s!^(){}']*$/;
                          if (stateRegex.test(value)) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject(t('invalidState')); // Message when validation fails
                          }
                        }
                      }
                    ]}
                  >
                    <Input placeholder={t('selectState')} size="large" />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              name="location"
              label={t('location')}
              rules={[
                {
                  validator: (_: any, value: string) => {
                    // If the field is empty, resolve since it's not required
                    if (!value) {
                      return Promise.resolve();
                    }

                    // Check if the field contains only spaces
                    if (value.trim() === '') {
                      return Promise.reject(new Error(t('onlyEmptySpaces')));
                    }

                    // Check if the field contains only special characters
                    const alphanumericPattern = /[A-Za-z0-9]/; // Alphanumeric character check
                    if (!alphanumericPattern.test(value)) {
                      return Promise.reject(new Error(t('onlySpecialCharValidation')));
                    }

                    // Check for max length
                    if (value.length > 150) {
                      return Promise.reject(new Error(t('cannotExceed150Characters')));
                    }

                    return Promise.resolve();
                  }
                }
              ]}
            >
              <Input size="large" placeholder={t('enterLocation')} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              name="postal_code"
              label={t('postalCode')}
              rules={[
                {
                  validator: (_: any, value: string) => {
                    // Skip validation if the field is empty (since it's not required)
                    if (!value) {
                      return Promise.resolve();
                    }

                    // Check if it contains only numbers
                    const numberPattern = /^\d+$/; // Pattern to match only numbers
                    if (!numberPattern.test(value)) {
                      return Promise.reject(new Error(t('allowedOnlyNumbers')));
                    }
                    if (/\s/.test(value)) {
                      return Promise.reject(new Error(t('noSpaceAllowed')));
                    }
                    // Check length (e.g., 5 or 6 digits)
                    if (value.length < 5 || value.length > 7) {
                      return Promise.reject(new Error(t('validPostCode')));
                    }

                    return Promise.resolve();
                  }
                }
              ]}
            >
              <Input size="large" placeholder={t('enterPostalCode')} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              name="member_type"
              label={t('sourceMemberType')}
              rules={[
                {
                  validator: (_: any, value: string) => {
                    // If the field is empty, resolve since it's not required
                    if (!value) {
                      return Promise.resolve();
                    }

                    // Check if the field contains only spaces
                    if (value.trim() === '') {
                      return Promise.reject(new Error(t('onlyEmptySpaces')));
                    }

                    // Check if the field contains only special characters
                    const alphanumericPattern = /[A-Za-z0-9]/; // Alphanumeric character check
                    if (!alphanumericPattern.test(value)) {
                      return Promise.reject(new Error(t('onlySpecialCharValidation')));
                    }

                    // Check for max length
                    if (value.length > 50) {
                      return Promise.reject(new Error(t('cannotExceed50Characters')));
                    }

                    return Promise.resolve();
                  }
                }
              ]}
            >
              <Input size="large" placeholder={t('sourceMemberType')} />
            </Form.Item>
          </Col>
        </Row>
      </div>

      {!isUpdate && (
        <div
          style={{
            height: 'fit-content',
            background: '#9b9b9b29',
            padding: '20px',
            marginBottom: '1rem',
            borderRadius: '10px'
          }}
        >
          <Typography.Title level={4} className="text-[#2e2a5b] m-0">
            {t('registeredChannelInfo')}
          </Typography.Title>
          <Row gutter={12}>
            <Col xs={12} md={6}>
              <Form.Item
                name="registered_channel_name"
                label={t('registeredChannel')}
                rules={[{ required: true, message: t('thisFieldIsRequired') }]}
              >
                <Select
                  options={allChannelOptions}
                  placeholder={t('registeredChannelName')}
                  allowClear
                  size="large"
                  onChange={(value: any, objData: any) => {
                    formInstance.setFieldsValue({
                      registered_channel_type: objData?.channel_type
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item name="registered_channel_type" label={t('registeredChannelType')}>
                <Input size="large" placeholder={t('registeredChannelType')} disabled />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                name="site_id"
                label={t('siteId')}
                rules={[
                  {
                    validator: (_: any, value: string) => {
                      // If the field is empty, resolve since it's not required
                      if (!value) {
                        return Promise.resolve();
                      }

                      // Check if the field contains only spaces
                      if (/\s/.test(value)) {
                        return Promise.reject(new Error(t('noSpaceAllowed')));
                      }

                      // Check if the field contains only special characters
                      const alphanumericPattern = /[A-Za-z0-9]/; // Check for at least one alphanumeric character
                      if (!alphanumericPattern.test(value)) {
                        return Promise.reject(new Error('onlySpecialCharValidation'));
                      }

                      return Promise.resolve();
                    }
                  }
                ]}
              >
                <Input size="large" placeholder={t('siteId')} />
              </Form.Item>
            </Col>
          </Row>
        </div>
      )}
      <div
        style={{
          height: 'fit-content',
          background: '#9b9b9b29',
          padding: '20px',
          marginBottom: '1rem',
          borderRadius: '10px'
        }}
      >
        <Typography.Title level={4} className="text-[#2e2a5b] m-0">
          {t('alternateCustomerIdentity')}
        </Typography.Title>
        <Form.List name="channel_identity_info">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                <Row gutter={12} key={key}>
                  <Col xs={12} md={6}>
                    <Form.Item
                      name={[name, 'channel_name']}
                      label={`${t('channel')} ${index + 1}`}
                      rules={[
                        {
                          required: true,
                          message: t('thisFieldIsRequired')
                        }
                      ]}
                    >
                      <Select
                        options={allChannelOptions}
                        placeholder={t('channelName')}
                        allowClear
                        size="large"
                        onChange={(value: any, objData: any) => {
                          formInstance.setFields([
                            {
                              name: ['channel_identity_info', name, 'channel_type'],
                              value: objData?.channel_type
                            }
                          ]);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Item name={[name, 'channel_type']} label={t('channelType')}>
                      <Input size="large" placeholder={t('channelType')} disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Item
                      name={[name, 'alternate_id']}
                      label={`${t('alternateId')} ${index + 1}`}
                      rules={[
                        { required: true, message: t('thisFieldIsRequired') },
                        {
                          validator: (_: any, value: string) => {
                            if (!value) {
                              return Promise.resolve();
                            }

                            if (/\s/.test(value)) {
                              return Promise.reject(new Error(t('noSpaceAllowed')));
                            }

                            // Check if the value contains only special characters
                            const alphanumericPattern = /[A-Za-z0-9]/; // Check for at least one alphanumeric character
                            if (!alphanumericPattern.test(value)) {
                              return Promise.reject(new Error(t('onlySpecialCharValidation')));
                            }

                            // Check for max length
                            if (value.length > 50) {
                              return Promise.reject(new Error(t('max50Characters')));
                            }

                            return Promise.resolve();
                          }
                        }
                      ]}
                    >
                      <Input size="large" placeholder={`${t('alternateId')} ${index + 1}`} />
                    </Form.Item>
                  </Col>
                  <Col className="ml-4 mt-8">
                    <DeleteOutlined
                      className="text-red-500 text-xl"
                      title={`${t('deleteChannel')} ${index + 1}`}
                      onClick={() => remove(name)}
                    />
                  </Col>
                </Row>
              ))}

              <Form.Item>
                <Button
                  type="primary"
                  onClick={async () => {
                    try {
                      const fieldsToValidate: any[] = [];

                      // Populate fieldsToValidate with paths for channel_identity_info fields
                      fields.forEach((_, index) => {
                        fieldsToValidate.push(['channel_identity_info', index, 'channel_name']);
                        fieldsToValidate.push(['channel_identity_info', index, 'alternate_id']);
                      });

                      // Validate only the specific fields in channel_identity_info
                      await formInstance.validateFields(fieldsToValidate);

                      // If validation passes, add a new field
                      add();
                    } catch (error) {
                      console.log('Validation failed:', error);
                    }
                  }}
                >
                  {t('addMore')}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </div>
      {!isUpdate && (
        <div
          style={{
            height: 'fit-content',
            background: '#9b9b9b29',
            padding: '20px',
            marginBottom: '1rem',
            borderRadius: '10px'
          }}
        >
          <Typography.Title level={4} className="text-[#2e2a5b] m-0">
            {t('loyaltyReward')}
          </Typography.Title>
          <Row gutter={12}>
            <Col xs={12} md={6}>
              <Form.Item label={t('rewardName')} name="reward_name">
                <Select
                  showSearch
                  allowClear
                  size="large"
                  filterOption={true}
                  placeholder={t('rewardName')}
                  notFoundContent={rewardDropDownFetch ? <Spin size="small" /> : null}
                  onSearch={async searchTerm => {
                    if (searchTerm) await debouncedFetchRewarsDropdownOptions(searchTerm);
                  }}
                  options={rewardOption}
                />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label={t('rewardPoints')}
                name="reward_points"
                rules={[
                  { required: false, message: t('thisFieldIsRequired') },
                  validationHelpers.getNumericValidatorForInput({})
                ]}
              >
                <InputNumber min={0} className="w-full" size="large" placeholder={t('rewardPoints')} />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label={t('activeInDays')}
                name="active_in_days"
                rules={[
                  { required: false, message: t('thisFieldIsRequired') },
                  validationHelpers.getNumericValidatorForInput({})
                ]}
              >
                <InputNumber min={0} className="w-full" size="large" placeholder={t('activeInDays')} />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label={t('expireInDays')}
                name="expire_in_days"
                rules={[
                  { required: false, message: t('thisFieldIsRequired') },
                  validationHelpers.getNumericValidatorForInput({})
                ]}
              >
                <InputNumber min={0} className="w-full" size="large" placeholder={t('expireInDays')} />
              </Form.Item>
            </Col>
          </Row>
        </div>
      )}
      <Row gutter={[12, 12]}>
        <Col xs={24} md={6}>
          <Button size="large" block onClick={handleGoBack}>
            <BoldButtonLabel labelText={t('goBack')} />
          </Button>
        </Col>
        {typeof handleFinish === 'function' && (
          <Col xs={24} md={6}>
            <Button size="large" block type="primary" htmlType="submit">
              <BoldButtonLabel labelText={isUpdate ? t('update') : t('create')} />
            </Button>
          </Col>
        )}
      </Row>
    </Form>
  );
};

export default CustomerForm;
